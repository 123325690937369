import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seo-agency',
  templateUrl: './seo-agency.component.html',
  styleUrls: ['./seo-agency.component.scss']
})
export class SeoAgencyComponent implements OnInit {
  currentDate = new Date();
  otherDate = new Date('2018-02-11');

  constructor() { }

  ngOnInit(): void {
  }

  getYearsDifference() {
    const currentYear = this.currentDate.getFullYear();
    const otherYear = this.otherDate.getFullYear();
    return currentYear - otherYear;
  }

}
