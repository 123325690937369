<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Nosotros</h3>
            <ul>
                <li><a href="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Nosotros</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Nosotros</span>
                        <h2>Conectando hogares y empresas al mundo digital</h2>
                    </div>
                    <h3>Gracias a nuestro enfoque en la calidad y la satisfacción del cliente, somos una de las empresas más respetadas y confiables del mercado.</h3>
                    <p class="justify">Nuestra empresa se especializa en proporcionar conectividad de alta velocidad y confiable a hogares y empresas. Contamos con un equipo de profesionales altamente cualificados y experimentados que brindan una amplia gama de servicios de instalación y configuración de dispositivos de conectividad y optimización de la red.</p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Nos esforzamos por ofrecer servicios de alta calidad y productos confiables a nuestros clientes.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Nos tomamos en serio nuestra responsabilidad de proporcionar conectividad de alta velocidad y confiable a nuestros clientes y trabajamos </p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Nos esforzamos por ser honestos, transparentes y éticos en todas nuestras acciones y decisiones.</p>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>{{getYearsDifference()}} Años</h3>
                    <p>DE SERVICIO</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>TASA DE SATISFACCIÓN</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>+ 5</h3>
                    <p>MUNICIPIOS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>+ 1000</h3>
                    <p>CLIENTES</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Sobre</span>
            <h2>Nuestra Empresa</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Misión:</h3>
                    <p class="justify">Nuestra misión es proporcionar conectividad de alta velocidad y confiable a hogares y empresas, brindando soluciones de internet personalizadas y de alta calidad para satisfacer las necesidades de nuestros clientes. Trabajamos constantemente para mejorar nuestros servicios y garantizar que nuestros clientes tengan acceso a internet rápido y fiable en todo momento.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check'></i> Creating Results.</li>
                        <li><i class='bx bx-check'></i> Good Thinking</li>
                        <li><i class='bx bx-check'></i> Expect More</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Visión:</h3>
                    <p class="justify">Nuestra visión es ser el proveedor líder de conectividad en nuestra región, ofreciendo servicios de internet de alta calidad y confiables a nuestros clientes. Nos esforzamos por ser innovadores en nuestro campo y siempre estamos buscando nuevas maneras de mejorar nuestros servicios y brindar valor a nuestros clientes. Aspiramos a ser reconocidos por nuestra excelencia en el servicio al cliente y por nuestra dedicación a proporcionar soluciones de internet de alta calidad a nuestros clientes.</p>
                    <!-- <ul>
                        <li><i class='bx bx-check'></i> We Have You Covered</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                        <li><i class='bx bx-check'></i> We Turn Heads</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                    </ul> -->
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Our History</h3>
                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                        <li><i class='bx bx-check'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Nuestros</span>
            <h2 class="h2-color2">Valores Institucionales</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Calidad: <span>1</span></h3>
                            <p class="justify">Nos esforzamos por ofrecer servicios de alta calidad y productos confiables a nuestros clientes. </p>
                        </li>
                        <li class="text-end">
                            <h3>Innovación: <span>2</span></h3>
                            <p class="justify">Nos esforzamos por ser innovadores en nuestro campo y estamos siempre buscando nuevas maneras de mejorar nuestros servicios. </p>
                        </li>
                        <li class="text-end">
                            <h3>Satisfaccióndel cliente: <span>3</span></h3>
                            <p class="justify">Nos esforzamos por brindar un servicio al cliente excepcional y hacer todo lo posible para satisfacer las necesidades de nuestros clientes.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Responsabilidad:</h3>
                            <p class="justify">Nos tomamos en serio nuestra responsabilidad de proporcionar conectividad de alta velocidad y confiable a nuestros clientes y trabajamos constantemente para garantizar que nuestros servicios sean de alta calidad. </p>
                        </li>
                        <li>
                            <h3><span>5</span>Integridad:</h3>
                            <p class="justify">Nos esforzamos por ser honestos, transparentes y éticos en todas nuestras acciones y decisiones. </p>
                        </li>
                        <li>
                            <h3><span>6</span>Sostenibilidad:</h3>
                            <p class="justify">Nos preocupamos por el medio ambiente y trabajamos para minimizar nuestro impacto ambiental y promover la sostenibilidad a largo plazo. </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2>The Facts About Our Company</h2>
        </div>

        <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2010</h3>
                        <span>February 20th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2012</h3>
                        <span>January 14th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2016</h3>
                        <span>March 25th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>December 10th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>February 01th</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Great Success of the Year</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip comodo consequa dolor consectetur</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation </p>
                    <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2>
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div> -->

<!-- <div class="testimonial-area testimonial-area-mb ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Words From Customers</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>David McLean</h3>
                                <span>CEO & Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Jermin Jekson</h3>
                                <span>Marketing Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Julfiker Jeain</h3>
                                <span>CEO At Ostino</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-logo-area></app-logo-area>

<app-footer-style-two></app-footer-style-two>
