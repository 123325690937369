<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <a href="https://www.icbf.gov.co/" target="_blank"><img src="assets/img/brand/brand-logo1.png" alt="Images"></a>
                </div>

                <div class="brand-logo-item">
                    <a href="https://www.policia.gov.co/dijin" target="_blank"><img src="assets/img/brand/brand-logo2.png" alt="Images"></a>
                </div>

                <div class="brand-logo-item">
                    <a href=" http://www.enticconfio.gov.co" target="_blank"><img src="assets/img/brand/brand-logo3.png" alt="Images"></a>
                </div>

                <div class="brand-logo-item">
                    <a href="https://www.fiscalia.gov.co/colombia/" target="_blank"><img src="assets/img/brand/brand-logo4.png" alt="Images"></a>
                </div>

                <div class="brand-logo-item">
                    <a href="http://www.internetsano.gov.co/" target="_blank"><img src="assets/img/brand/brand-logo5.png" alt="Images"></a>
                </div>

                <div class="brand-logo-item">
                    <a href="https://www.mintic.gov.co/portal/inicio/" target="_blank"><img src="assets/img/brand/brand-logo6.png" alt="Images"></a>
                </div>

                <div class="brand-logo-item">
                    <a href="https://teprotejocolombia.org/" target="_blank"><img src="assets/img/brand/brand-logo7.png" alt="Images"></a>
                </div>
            </div>
        </div>
    </div>
</div>
