<footer class="footer-area footer-bg pt-100 pb-70">
    <div class="container white">
        <div class="footer-top">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="footer-img">
                        <img src="assets/img/logo/logo3.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="footer-social-icon">
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/profile.php?id=100063748676419" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="" target="_blank"><i class='bx bxl-whatsapp'></i></a></li>
                            <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-midal pt-45 pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-5">
                    <div class="footer-widget">
                        <h3>Servicios</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/planes">Planes</a></li>
                            <li><a routerLink="/cobertura">Cobertura</a></li>
                            <li><a routerLink="/contacto">Soporte tecnológico</a></li>
                            <li><a routerLink="/test">Test de velocidad</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <h3>Empresa</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">inicio</a></li>
                            <li><a routerLink="/nosotros">Nosotros</a></li>
                            <li><a routerLink="/utilidades">Utilidades</a></li>
                            <li><a routerLink="/contacto">Contacto</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-5">
                    <div class="footer-widget pl-5">
                        <h3>Aprendiendo</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/entes-de-control">Entes de control</a></li>
                            <li><a routerLink="/proteccion-y-seguridad-en-la-red">Protección y seguridad en la red</a></li>
                            <li><a routerLink="/contacto">Formularios PQRS</a></li>
                            <!-- <li><a routerLink="/indicadores">Indicadores</a></li> -->
                        </ul>
                    </div>
                </div>

                <!-- <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <h3>Newsletter</h3>
                        <p>To get the latest news and latest up- dates from us</p>
                        <div class="newsletter-area">
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Your Email*" name="EMAIL" required autocomplete="off">
                                <button class="default-btn" type="submit">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="copy-right-area">
            <div class="copy-right-text text-center">
                <p>Copyright @{{ year }}. Todos los derechos reservados por <a href="/" target="_blank">Intered Colombia S.A.S.</a> </p>
            </div>
        </div>
    </div>
</footer>
