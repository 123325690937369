<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Entes de control</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Entes de control</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="team-area-two pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Regulación</span>
            <h2 class="h2-color2">Entes de control</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <div class="entes">
                        <img src="assets/img/entes-control/crc.svg" alt="Images">
                    </div>
                    <div class="content">
                        <h3>Comisión de Regulación de Comunicaciones CRC</h3>
                        <p class="info"><i class='bx bxl-facebook'></i> Calle 59A Bis No. 5-53 Edificio Link Siete Setenta Piso 9</p>
                        <p class="info"><i class='bx bxl-twitter'></i> 319 8300 - 01-8000-919278</p>
                        <a class="info" href="https://www.crcom.gov.co/" target="_blank"><i class='bx bxl-pinterest-alt'></i> www.crcom.gov.co</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <div class="entes">
                        <img src="assets/img/entes-control/mintic.svg" alt="Images">
                    </div>
                    <div class="content">
                        <h3>Ministerio de Tecnologías de la Información y las Comunicaciones MINTIC</h3>
                        <p class="info"><i class='bx bx-map-pin'></i> Edificio Murillo Toro, Carrera 8 Calle 13 Bogotá,Colombia</p>
                        <p class="info"><i class='bx bx-phone'></i> PBX +(571)344 34 60</p>
                        <a class="info" href="http://www.mintic.gov.co/" target="_blank"><i class='bx bx-world'></i> www.mintic.gov.co</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <div class="entes">
                        <img src="assets/img/entes-control/sic.png" alt="Images">
                    </div>
                    <div class="content">
                        <h3>Superintendencia de Industria y Comercio SIC</h3>
                        <p class="info"><i class='bx bx-map-pin'></i> Carrera 13 No.27 – 00 Piso 1 y 3</p>
                        <p class="info"><i class='bx bx-phone'></i> (571) 592 04 00 – Bog.
                            (571) 587 00 00 Fax: (571) 587 02 84</p>
                        <a class="info" href="http://www.sic.gov.co/" target="_blank"><i class='bx bx-world'></i> www.sic.gov.co</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<app-logo-area></app-logo-area>

<app-footer-style-two></app-footer-style-two>
