import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utilidades',
  templateUrl: './utilidades.component.html',
  styleUrls: ['./utilidades.component.scss']
})
export class UtilidadesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
