<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <!-- <div class="title">
                        <i class="flaticon-idea"></i>
                        <span>The Generator</span>
                    </div> -->
                    <h1>Conectando hogares y empresas al  <b>mundo digital</b></h1>
                    <p>Gracias a nuestro enfoque en la calidad y la satisfacción del cliente, somos una de las empresas más respetadas y confiables del mercado. </p>
                    <div class="banner-btn">
                        <a routerLink="/contacto" class="get-btn">Contáctanos<i class='bx bx-plus'></i></a>
                        <a href="tel:+573155664701" class="call-btn"><i class='bx bx-plus'></i>+57 315 5664701</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="banner-shape-right"><img src="assets/img/home1/home-one-shape.png" alt="Images"></div> -->
    <div class="banner-img"><img src="assets/img/home1/home2.png" alt="Images"></div>
    <div class="banner-shape">
        <div class="shape1"><img src="assets/img/home1/home-one-shape3.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/home1/home-one-shape1.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/home1/home-one-shape2.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/home1/home-one-shape4.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/home1/home-one-shape5.png" alt="Images"></div>
    </div>
</div>

<!-- <div class="brand-area">
    <div class="container-fluid">
        <div class="brand-max">
            <div class="brand-title">
                <h3>Over <a href="#" target="_blank">150,000</a> supporter all ovet the world</h3>
            </div>

            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand1.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand2.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand3.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand4.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand5.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand6.png" alt="Images"></a>
                </div>

                <div class="brand-item">
                    <a href="#" target="_blank"><img src="assets/img/brand/brand2.png" alt="Images"></a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="about-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="assets/img/about/about-img1.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="section-title">
                            <span class="sp-after">About us</span>
                            <h2 class="h2-color">Grow Your Business <b>With Our Agency</b></h2>
                        </div>
                        <h3>We have 30 yearsOur strategy includes consistently evolving, to ensure we’re producing exceptional SEO for business.</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae tempor nisl. Etiam magna augue, tempus eget suscipit et, maximus hendrerit nunc. Nunc elementum non ipsum vitae molestie. Donec vestibulum lobortis congue. Mauris sed tincidunt leo Suspendisse accumsan.</p>
                        <div class="about-btn">
                            <a routerLink="/about" class="default-btn">Learn More<i class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-btn popup-btn"><i class="flaticon-play-button"></i><span> Watch 1min <br>Play demo</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape">
        <div class="shape-1"><img src="assets/img/about/about-shape1.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape2.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape3.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
    </div>
</div> -->

<div class="counter-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <img class="icon" src="assets\svg\celebracion.svg" alt="">
                    <div class="content">
                        <h3 class="red">{{getYearsDifference()}} Años</h3>
                        <p>DE SERVICIO</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <img class="icon" src="assets\svg\satisfaccion.svg" alt="">
                    <div class="content">
                        <h3 class="red">100%</h3>
                        <p>TASA DE SATISFACCIÓN </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <img class="icon" src="assets\svg\clientes.svg" alt="">
                    <div class="content">
                        <h3 class="red">+ 1000</h3>
                        <p>CLIENTES</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <img class="icon" src="assets\svg\pais.svg" alt="">
                    <div class="content">
                        <h3 class="red">+ 5</h3>
                        <p>MUNICIPIOS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Utilidades</span>
            <h2 class="h2-color">Somos flexibles para <b> brindarle lo mejor</b></h2>
        </div>

        <div class="row pt-45">
            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">App Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/cobertura"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a routerLink="/cobertura">Cobertura</a></h3>
                    <p>Conozca cobertura de servicios de acceso a Internet para clientes hogares y empresa.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/contacto"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a routerLink="/contacto">Soporte tecnológico</a></h3>
                    <p>Asistencias para instalar, configurar y soportar los equipos de tecnología de tú hogar y empresa.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/test"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/test">Test de velocidad</a></h3>
                    <p>Prueba la velocidad y conoce el estado de tu conexión a internet hogar o empresarial.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Web Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">PPC Advertising</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/services" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services" class="page-numbers">2</a>
                    <a routerLink="/services" class="page-numbers">3</a>
                    <a routerLink="/services" class="page-numbers">4</a>
                    <a routerLink="/services" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<!-- <div class="progress-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Real Progress</span>
            <h2 class="h2-color">Super Great Things From <b>Your Desire Wishes</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-1">
                    <img src="assets/img/progress-icon/progress-icon1.png" alt="Images">
                    <h3>28,998</h3>
                    <p>RANKING KEYWORDS</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-2">
                    <img src="assets/img/progress-icon/progress-icon2.png" alt="Images">
                    <h3>600%</h3>
                    <p>ANNUAL ORGANIC TRAFFIC</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3  offset-lg-0">
                <div class="progress-card pr-bg-color-3">
                    <img src="assets/img/progress-icon/progress-icon3.png" alt="Images">
                    <h3>50,234</h3>
                    <p>RANKING KEYWORDS</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="seo-area">
    <div class="container-fluid">
        <div class="seo-width">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="seo-form">
                        <h2>What is My SEO Score?</h2>

                        <div class="seo-form-area">
                            <form class="seo-form-option">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-place'></i>
                                            <input class="form-control form-border" type="text" placeholder="Your Website URL">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-vision'></i>
                                            <input class="form-control" type="email"  placeholder="Enter Your Email">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4 offset-sm-3 offset-md-0 offset-lg-0">
                                        <button type="submit" class="default-btn">
                                            Get started
                                            <i class='bx bx-plus'></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="seo-img">
                        <img src="assets/img/seo-rank.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="services-card-into">
                    <div class="services-card">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                        <h3><a routerLink="/services-details">App Development</a></h3>
                        <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                        <div class="services-card-shape">
                            <img src="assets/img/service/service-shape.png" alt="Images">
                        </div>
                    </div>
                    <div class="services-dots"><img src="assets/img/service/dots.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Content Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">SEO Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Web Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">PPC Advertising</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="services-shape">
        <div class="services-bg"><img src="assets/img/service/service-bg.png" alt="Images"></div>
        <div class="shape1"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div> -->

<!-- <div class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Working Process</span>
            <h2 class="h2-color">Simple & Clean <b>Work Process</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <h2>01</h2>
                    <h3>Onsite Optimization</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card active">
                    <h2>02</h2>
                    <h3>Keyword Research</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="work-card">
                    <h2>03</h2>
                    <h3>Link Building</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>
        </div>
    </div>

    <div class="work-shape"><img src="assets/img/shape/work-shape.png" alt="Images"></div>
</div> -->
<div class="project-area">
    <div class="container">
        <div class="project-content">
            <h2>Protección y seguridad en la red</h2>
            <h3>Conoce más acerca de seguridad y protección en la red.</h3>
            <a routerLink="/proteccion-y-seguridad-en-la-red" class="default-btn">Ver más<i class='bx bx-plus'></i></a>
        </div>
    </div>

    <div class="project-shape">
        <div class="shape1"><img src="assets/img/shape/shape17.png" alt=""></div>
        <div class="shape2"><img src="assets/img/shape/shape18.png" alt=""></div>
    </div>
</div>

<div class="pricing-area pt-50 pb-50">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Plan de precios</span>
            <h2 class="h2-color">Plan de precios y  <b>Paquetes</b></h2>
        </div>

        <div class="section-title text-center">
            <h2 class="h2-color"><b>Huila</b> - Fibra óptica</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1" style="font-size: 18px;">Internet 20 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1" style="font-size: 40px;">$ 50.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul style="font-size: 14px;">
                                <li><i class='bx bx-check'></i> Hasta 20 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 10 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2" style="font-size: 18px;">Internet 30 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2" style="font-size: 40px;">$ 60.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul style="font-size: 14px;">
                                <li><i class='bx bx-check'></i> Hasta 30 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 15 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1" style="font-size: 18px;">Internet 40 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1" style="font-size: 40px;">$ 75.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul style="font-size: 14px;">
                                <li><i class='bx bx-check'></i> Hasta 40 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 20 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Huila</b> - Radio enlace zona rural</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 8 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 60.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 8 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 4 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 10 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 75.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 10 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 12 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 90.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 12 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Cauca</b> - Fibra óptica</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 10 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 55.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 10 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 12 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 70.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 12 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 14 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 90.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 14 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 7 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-3">Internet 15 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-3">$ 100.000</h2>
                                <span class="color-3">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 15 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 7.5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Cauca</b> - Radio enlace zona rural</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 8 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 55.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 8 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 4 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 10 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 70.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 10 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 12 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 90.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 12 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-3">Internet 13 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-3">$ 100.000</h2>
                                <span class="color-3">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 13 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6.5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Cauca</b> - Antena (comercial)</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 14 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 120.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 14 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 7 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 16 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 150.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 16 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 8 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 20 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 180.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 20 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 10 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>


    <a href="https://www.crcom.gov.co/es/micrositios/comunicados-como-es" target="_blank"><div class="shape1"><img src="assets/img/comunicados-como-es-banner.jpg" alt=""></div></a>

<!-- <div class="team-area pt-100 pb-70">
    <div class="container-fluid p-0 m-0">
        <div class="section-title text-center">
            <span class="sp-after">Our Specialists</span>
            <h2 class="h2-color">Meet Our <b>Leadership Team</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p>
        </div>

        <div class="team-slider owl-carousel owl-theme pt-45">
            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before"><img src="assets/img/team/team-img1.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Hobler Jeain</a></h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-2"><img src="assets/img/team/team-img2.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Jermin Jekson</a></h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-3"><img src="assets/img/team/team-img3.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Cristiono Kopper</a></h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before"><img src="assets/img/team/team-img4.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Julfiker Jeain</a></h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-2"><img src="assets/img/team/team-img5.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Rickey poltin</a></h3>
                    <span>Area Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/team" class="team-img team-bg-before-3"><img src="assets/img/team/team-img6.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/team">Jeck Keplin</a></h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-shape">
        <div class="team-shape1"><img src="assets/img/shape/team-shape.png" alt="Images"></div>
        <div class="team-shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="team-shape3"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="team-shape4"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div> -->

<div class="blog-area blog-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-8 col-md-12">
                <div class="blog-title">
                    <div class="section-title">
                        <span class="sp-after">Our Blogs</span>
                        <h2 class="h2-color">Latest Valuable <b>Insights</b></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-12">
                <div class="blog-btn">
                    <a routerLink="/blog" class="default-btn">See all news<i class='bx bx-plus'></i></a>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/proteccion-y-seguridad-en-la-red"><img src="assets/img/home1/neutralidad.png" alt="Images"></a>
                    <!-- <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a href="https://www.tendacn.com/co/product/specification/hg6.html" target="_blank"><img src="assets/img/home1/equipos.png" alt="Images"></a>
                    <!-- <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a href="https://crcom.gov.co/system/files/Biblioteca%20Virtual/contrato%20%C3%BAnico%20de%20servicios%20fijos/contrato_postpago_fijos2.pdf" target="_blank"><img src="assets/img/home1/contrato.png" alt="Images"></a>
                    <!-- <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<app-logo-area></app-logo-area>

<div class="contact-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-img">
                    <img src="assets/img/contact-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="section-title">
                            <span class="sp-before sp-after">Contáctanos</span>
                            <h2>Contacta con nosotros</h2>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Nombres y Apellidos*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Teléfono">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Asunto">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Mensaje"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius">Enviar mensaje <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1993.1826558212892!2d-75.89509984516972!3d2.3839175635006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9960da6546288d01!2sIntered%20Colombia!5e0!3m2!1ses-419!2sco!4v1672272052881!5m2!1ses-419!2sco"></iframe>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
