<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/logo3.png" alt="Images">
                        </div>
                        <p class="justify">Gracias a nuestro enfoque en la calidad y la satisfacción del cliente, somos una de las empresas más respetadas y confiables del mercado.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/profile.php?id=100063748676419" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="" target="_blank"><i class='bx bxl-whatsapp'></i></a></li>
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Recursos</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/entes-de-control">Entes de control</a></li>
                            <li><a routerLink="/proteccion-y-seguridad-en-la-red">Protección y seguridad en la red</a></li>
                            <li><a routerLink="/contacto">Formularios PQRS</a></li>
                            <!-- <li><a routerLink="/indicadores">Indicadores</a></li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Empresa</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Inicio</a></li>
                            <li><a routerLink="/nosotros">Nosotros</a></li>
                            <li><a routerLink="/planes">Planes</a></li>
                            <li><a routerLink="/utilidades">utilidades</a></li>
                            <li><a routerLink="/contacto">Contacto</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Dirección</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+573155664701">(+57) 315 5664701</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:soporte@interedcolombia.com.co">soporte@interedcolombia.com.co</a></li>
                            <li><i class='flaticon-place'></i>Carrera 5 #5 – 25 La Plata - Huila, Colombia</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>Copyright @{{ year }}. Todos los derechos reservados por <a href="/" target="_blank">Intered Colombia S.A.S.</a> </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <!-- <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li> -->
                            <li><a href=""> Web site by: ITS-Solutions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
