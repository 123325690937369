<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Plan de precios</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Plan de precios</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-50 pb-50">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Plan de precios</span>
            <h2 class="h2-color">Plan de precios y  <b>Paquetes</b></h2>
        </div>

        <div class="section-title text-center">
            <h2 class="h2-color"><b>Huila</b> - Fibra óptica</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1" style="font-size: 18px;">Internet 20 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1" style="font-size: 40px;">$ 50.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul style="font-size: 14px;">
                                <li><i class='bx bx-check'></i> Hasta 20 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 10 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2" style="font-size: 18px;">Internet 30 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2" style="font-size: 40px;">$ 60.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul style="font-size: 14px;">
                                <li><i class='bx bx-check'></i> Hasta 30 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 15 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1" style="font-size: 18px;">Internet 40 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1" style="font-size: 40px;">$ 75.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul style="font-size: 14px;">
                                <li><i class='bx bx-check'></i> Hasta 40 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 20 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Huila</b> - Radio enlace zona rural</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 8 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 60.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 8 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 4 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 10 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 75.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 10 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 12 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 90.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 12 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Cauca</b> - Fibra óptica</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 10 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 55.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 10 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 12 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 70.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 12 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 14 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 90.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 14 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 7 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-3">Internet 15 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-3">$ 100.000</h2>
                                <span class="color-3">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 15 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 7.5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Cauca</b> - Radio enlace zona rural</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 8 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 55.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 8 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 4 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 10 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 70.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 10 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 12 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 90.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 12 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-3">Internet 13 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-3">$ 100.000</h2>
                                <span class="color-3">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 13 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 6.5 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title text-center mt-5">
            <h2 class="h2-color"><b>Cauca</b> - Antena (comercial)</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 14 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 120.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 14 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 7 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-2">Internet 16 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 150.000</h2>
                                <span class="color-2">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 16 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 8 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-planet-earth pricing-icon color-1"></i>
                            <h3 class="color-1">Internet 20 megas</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 180.000</h2>
                                <span class="color-1">MENSUAL</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 20 megas bajada</li>
                                <li><i class='bx bx-check'></i> Hasta 10 megas de subida</li>
                                <li><i class='bx bx-check'></i> Puntos cableados incluidos 1</li>
                                <li><i class='bx bx-check'></i> wifi</li>
                            </ul>
                            <a routerLink="/contacto" class="purchase-btn button-bg3">Contratar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pb-50">
    <div class="container-fluid">
        <div class="section-title text-center">
            <h2 class="h2-color">ten en cuenta:</h2>
        </div>
        <div class="price-width">
            <div class="row">
                <div class="col-lg-12 col-sm-12">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <ul>
                                <li><i class='bx bx-check'></i> Pecios sujetos a viabilidad técnica.</li>
                                <li><i class='bx bx-check'></i> Los Precios anteriormente descritos no incluyen los gastos de instalación.</li>
                                <li><i class='bx bx-check'></i> Los Precios anteriormente descritos aplican para La Plata - Huila</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pricing-area pb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <h2 class="h2-color">ten en cuenta estas recomendaciones para mejorar tu navegación</h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <img src="assets/svg/OneFingers.svg" class=" pricing-icon color-1" alt="">
                            <h3 class="color-1 recomendacion">Deja el cable módem en un espacio central y despejado.</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <img src="assets/svg/TwoFingers.svg" class=" pricing-icon color-1" alt="">
                            <h3 class="color-1 recomendacion">La velocidad por WIFI puede variar dependiendo la distancia y ubicación del router.</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <img src="assets/svg/ThreeFingers.svg" class="pricing-icon color-1" alt="">
                            <h3 class="color-1 recomendacion">La navegación puede verse afectada por cantidad de dispositivos conectados simultáneamente, muros o equipos de viviendas vecinas.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<app-logo-area></app-logo-area>

<app-footer-style-two></app-footer-style-two>
