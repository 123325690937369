<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Protección y seguridad en la red</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Protección y seguridad en la red</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="blog-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Regulación</span>
            <h2 class="h2-color2">Protección y seguridad en la red</h2>
            <p class="margin-auto justify">Intered Colombia S.A.S no ofrece servicios de ciberseguridad como protección contra spam, antivirus, filtrado de contenido ni escaneo de correo electrónico. Sin embargo, se realiza una concientización sobre las amenazas y riesgos actuales y se brindan pautas para que los usuarios puedan proteger su identidad e información.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink=""><img src="assets/img/proteccion/proteccion1.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="">Internet sano</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink=""><img src="assets/img/proteccion/proteccion2.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="">Prevención pornografía infantil</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink=""><img src="assets/img/proteccion/proteccion3.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="">Control Parental</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a href="/assets/docs/cyb_col_ley_679_2001.pdf" target="_blank"><img src="assets/img/proteccion/proteccion4.png" alt="Images"></a>
                    <div class="content">
                        <h3><a href="/assets/docs/cyb_col_ley_679_2001.pdf" target="_blank">Ley 679 de 2001</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a href="/assets/docs/ley_1336_de_2009_colombia.pdf" target="_blank"><img src="assets/img/proteccion/proteccion5.png" alt="Images"></a>
                    <div class="content">
                        <h3><a href="/assets/docs/ley_1336_de_2009_colombia.pdf" target="_blank">Ley 1336 de 2009</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a href="/assets/docs/Decreto_1524_de_2002.pdf" target="_blank"><img src="assets/img/proteccion/proteccion6.png" alt="Images"></a>
                    <div class="content">
                        <h3><a href="/assets/docs/Decreto_1524_de_2002.pdf" target="_blank">Decreto 1524 de 2002</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<div class="price-width">
    <div class="section-title text-center pb-100">
        <h2 class="h2-color2">ten en cuenta estas recomendaciones para mejorar tu navegación</h2>
    </div>
    <div class="row">
        <div class="col-lg-4 col-sm-6">
            <div class="pricing-card">
                <div class="pricing-card-into color-bg1">
                    <img src="assets/svg/OneFingers.svg" class=" pricing-icon color-1" alt="">
                    <h3 class="color-1 recomendacion">Deja el cable módem en un espacio central y despejado.</h3>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="pricing-card">
                <div class="pricing-card-into color-bg1">
                    <img src="assets/svg/TwoFingers.svg" class=" pricing-icon color-1" alt="">
                    <h3 class="color-1 recomendacion">La velocidad por WIFI puede variar dependiendo la distancia y ubicación del router.</h3>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
            <div class="pricing-card">
                <div class="pricing-card-into color-bg1">
                    <img src="assets/svg/ThreeFingers.svg" class="pricing-icon color-1" alt="">
                    <h3 class="color-1 recomendacion">La navegación puede verse afectada por cantidad de dispositivos conectados simultáneamente, muros o equipos de viviendas vecinas.</h3>
                </div>
            </div>
        </div>
    </div>
</div>

<app-logo-area></app-logo-area>

<app-footer-style-two></app-footer-style-two>
