<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Cobertura</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li><a routerLink="/utilidades">Utilidades</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Cobertura</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/coberturaLaPlata.png" alt="Images">
                        <h2>Nuestra Cobertura</h2>
                        <p>Recuerda que la cobertura de nuestro servicio aplica para los municipio de:</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>La Plata - Huila</h3>
                                <p>Fibra óptica - zona urbana</p>
                                <p>Radioenlace - zona rural</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Tesalia, Paicol - Huila</h3>
                                <p>Fibra óptica - zona urbana</p>
                                <p>Radioenlace - zona rural</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Inzá, Belalcázar - Cauca</h3>
                                <p>Radioenlace</p>
                                <p>zona urbana y rural</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>San Andrés de Pisimbala, Pedregal - Cauca</h3>
                                <p>Fibra óptica - zona urbana</p>
                                <p>Radioenlace - zona rural</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>¿Tienes alguna pregunta?</h2>
                                    <p class="justify">Para mas información comunícate a nuestras líneas de contacto donde un agente comercial estará listo para informarte si tu predio cuenta con disponibilidad de Cobertura de nuestros servicios!</p>
                                    <a routerLink="/contacto" class="default-btn">Contáctanos<i class='bx bx-plus'></i></a>
                                    <!-- <ul>
                                        <li><i class='bx bx-check'></i> The Field of Data Science</li>
                                        <li><i class='bx bx-check'></i> SEO is Uniquely Built Around  </li>
                                        <li><i class='bx bx-check'></i> Google’s search algorithm</li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore tus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis onsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->

                    <!-- <div class="services-widget-list">
                        <ul>
                            <li class="active">
                                <span>75%</span>
                                <div class="content list1">
                                    <h3>SEO & Marketing</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Keywords Results</h3>
                                </div>
                                <span>66%</span>
                            </li>
                            <li>
                                <span>43%</span>
                                <div class="content list3">
                                    <h3>Google Analytics</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Off Page SEO</h3>
                                </div>
                                <span>15%</span>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="widget-category">
                        <h3>Otros servicios</h3>

                        <ul>
                            <li><a routerLink="/test">Test de velocidad</a></li>
                            <li><a routerLink="/contacto">Soporte tecnológico</a></li>
                        </ul>
                        <hr>
                        <ul>
                            <li><a routerLink="/entes-de-control">Entes de control</a></li>
                            <li><a routerLink="/proteccion-y-seguridad-en-la-red">Protección y seguridad en la red</a></li>
                            <li><a routerLink="/contacto">Formularios PQRS</a></li>
                            <li><a routerLink="/indicadores">Indicadores</a></li>
                        </ul>
                    </div>

                    <div class="contact-widget">
                        <h2>Datos de contacto</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Teléfono: </h3>
                                    <span>La Plata</span>
                                    <span><a href="tel:+573155664701">(+57) 315 5664701</a></span>
                                    <span>San Andrés de Pisimbala</span>
                                    <span><a href="tel:+573107512651">(+57) 310 7512651</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:soporte@interedcolombia.com.co">soporte@interedcolombia.com.co</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Localización:</h3>
                                    <span>Carrera 5 #5 – 25 La Plata - Huila, Colombia</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="/services-details">PDF Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="/services-details">Services Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-logo-area></app-logo-area>

<app-footer-style-two></app-footer-style-two>
