<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/logo1.png" alt="Logo"></a>
    </div>

    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo4.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Inicio</a></li>

                        <li class="nav-item"><a routerLink="/nosotros" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nosotros</a></li>

                        <li class="nav-item"><a routerLink="/planes" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Planes</a></li>

                        <li class="nav-item"><a routerLink="/utilidades" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Utilidades</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Regulación<i class='bx bx-plus'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/entes-de-control" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Entes de control</a></li>

                                <li class="nav-item"><a routerLink="/proteccion-y-seguridad-en-la-red" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Protección y seguridad en la red</a></li>

                                <li class="nav-item"><a routerLink="/contacto" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Formularios PQRS</a></li>

                                <!-- <li class="nav-item"><a routerLink="/indicadores" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Indicadores</a></li> -->

                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contacto" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contacto</a></li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        <!-- <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div> -->
                        <!-- <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div> -->
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contacto" class="nav-menu-btn">Contáctanos <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contacto" class="nav-menu-btn">Contáctanos <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>

            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
