import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  currentDate = new Date();
  otherDate = new Date('2018-02-11');
  constructor() { }

  ngOnInit(): void {
  }

  getYearsDifference() {
    const currentYear = this.currentDate.getFullYear();
    const otherYear = this.otherDate.getFullYear();
    return currentYear - otherYear;
  }
}
