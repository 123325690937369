<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Utilidades</h3>
            <ul>
                <li><a href="index.html">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Utilidades</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Utilidades</span>
            <h2 class="h2-color">Somos flexibles para <b> brindarle lo mejor</b></h2>
        </div>

        <div class="row pt-45">
            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">App Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/cobertura"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a routerLink="/cobertura">Cobertura</a></h3>
                    <p>Conozca cobertura de servicios de acceso a Internet para clientes hogares y empresa.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/contacto"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a routerLink="/contacto">Soporte tecnológico</a></h3>
                    <p>Asistencias para instalar, configurar y soportar los equipos de tecnología de tú hogar y empresa.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/test"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/test">Test de velocidad</a></h3>
                    <p>Prueba la velocidad y conoce el estado de tu conexión a internet hogar o empresarial.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">Web Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/services-details">PPC Advertising</a></h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div> -->

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/services" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services" class="page-numbers">2</a>
                    <a routerLink="/services" class="page-numbers">3</a>
                    <a routerLink="/services" class="page-numbers">4</a>
                    <a routerLink="/services" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>

<app-logo-area></app-logo-area>

<app-footer-style-two></app-footer-style-two>
