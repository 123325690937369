<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Error 404</h3>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Error 404</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="error-content">
                <img src="assets/img/404-error.png" alt="Image">
                <h3>¡Ups! Página no encontrada</h3>
                <p>Es posible que la página que está buscando se haya eliminado o no este disponible temporalmente.</p>
                <a routerLink="/" class="default-btn-two">Volver a la página de inicio</a>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
