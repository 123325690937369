import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-style-four',
  templateUrl: './navbar-style-four.component.html',
  styleUrls: ['./navbar-style-four.component.scss']
})
export class NavbarStyleFourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
